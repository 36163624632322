<form [formGroup]="interviewForm" class="form">
  <div class="form-group">
    <label>{{ 'INTERVIEW.INTERVIEW_SUBJECT' | translate }}</label>
    <input formControlName="subject"
           class="form-control subject-control"
           [ngClass]="{
             'is-invalid': subject.invalid && subject.touched
           }"/>
    <div class="invalid-feedback">
      {{ subject.errors?.minlength ? ('GENERIC_ERROR_MESSAGES.MIN_LENGTH' | translate: { minLength: subjectMinLength }) : ('GENERIC_ERROR_MESSAGES.REQUIRED' | translate) }}
    </div>
  </div>

  <app-quill-editor [form]="interviewForm"
                    [showLinkPlugin]="true"
                    controlName="introForEmailInvitation"
                    [textFormControl]="introForEmailInvitationText">
  </app-quill-editor>
  <div class="form-group">
    <div class="is-invalid">
    </div>
    <div *ngIf="introForEmailInvitation.touched && introForEmailInvitation.errors" class="invalid-feedback">
      <span *ngIf="introForEmailInvitation.errors?.doesntContainLink">
        {{ 'INTERVIEW.NO_LINK_ERROR' | translate }}
      </span>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="preview-button-wrapper">
    <button class="btn btn-primary border-white" (click)="previewEmail()" type="button">
      {{ 'BUTTONS.EMAIL_PREVIEW' | translate }}
    </button>
  </div>
</form>
<div class="add-event-container">
  <div class="calendar-and-events-container" [ngClass]="{'in-modal': openInModal}">
    <app-calendar [isEventPicker]="true"
                  [minDate]="minDate"
                  (selectedDateChange)="onSelectedDateChange($event)">
    </app-calendar>

    <div class="suggested-event-list-wrapper" *ngIf="time.value.length > 0">
      <div class="event-list-title">
        {{ 'INTERVIEW.SUGGESTED_TIMES' | translate }}
      </div>
      <div class="suggested-event-list">
        <div *ngFor="let timeSlot of time.value; let i = index" class="event">
          {{ timeSlot.start | dateDayMonth }}
          <span class="event-time">
            <span class="time">
              {{ timeSlot.start | dateTime }} - {{ timeSlot.end | dateTime }}
            </span>
          </span>
          <i class="icon icon-bin" (click)="removeSuggestedEvent(i)"></i>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="btn btn-outline-primary" (click)="cancel()">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
      <button class="btn btn-primary" (click)="sendEvent()" *ngIf="time.value.length > 0">
        {{ 'SHARED.SEND_BUTTON' | translate }}
      </button>
    </div>
  </div>

  <div class="event-selecting-wrapper">
    <label>{{ 'JOBS.WORK_HOURS' | translate }}</label>
    <div class="work-time-container">
      <div class="time-container">
        <label>{{ 'INTERVIEW.START' | translate }}</label>
        <form [formGroup]="workHoursStart" class="time-inputs-container">

          <div class="form-group">
            <select class="form-control"
                    formControlName="hour">
              <option *ngFor="let hour of hourArray" [ngValue]="hour.value">
                {{ hour.name }}
              </option>
            </select>
          </div>

          <span class="time-divider">:</span>

          <div class="form-group">
            <select class="form-control"
                    formControlName="minute">
              <option *ngFor="let minute of minutesArray | slice : 0 : (workHoursEnd.value.hour === 24 ? 1 : minutesArray.length)" [ngValue]="minute.value">
                {{ minute.name }}
              </option>
            </select>
          </div>
        </form>
      </div>

      <div class="time-container">
        <label>{{ 'INTERVIEW.END' | translate }}</label>
        <form [formGroup]="workHoursEnd" class="time-inputs-container">

          <div class="form-group">
            <select class="form-control"
                    formControlName="hour">
              <option *ngFor="let hour of hourArray" [ngValue]="hour.value">
                {{ hour.name }}
              </option>
            </select>
          </div>

          <span class="time-divider">:</span>

          <div class="form-group">
            <select class="form-control"
                    formControlName="minute">
              <option *ngFor="let minute of minutesArray | slice : 0 : (workHoursEnd.value.hour === 24 ? 1 : minutesArray.length)" [ngValue]="minute.value">
                {{ minute.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </div>

    <label>{{ 'INTERVIEW.LUNCH_BREAK' | translate }}</label>
    <div class="work-time-container">
      <div class="time-container">
        <label>{{ 'INTERVIEW.START' | translate }}</label>
        <form [formGroup]="lunchBreakStart" class="time-inputs-container">

          <div class="form-group">
            <select class="form-control"
                    formControlName="hour">
              <option *ngFor="let hour of hourArray" [ngValue]="hour.value">
                {{ hour.name }}
              </option>
            </select>
          </div>

          <span class="time-divider">:</span>

          <div class="form-group">
            <select class="form-control"
                    formControlName="minute">
              <option *ngFor="let minute of minutesArray | slice : 0 : (workHoursEnd.value.hour === 24 ? 1 : minutesArray.length)" [ngValue]="minute.value">
                {{ minute.name }}
              </option>
            </select>
          </div>
        </form>
      </div>

      <div class="time-container">
        <label>{{ 'INTERVIEW.END' | translate }}</label>
        <form [formGroup]="lunchBreakEnd" class="time-inputs-container">

          <div class="form-group">
            <select class="form-control"
                    formControlName="hour">
              <option *ngFor="let hour of hourArray" [ngValue]="hour.value">
                {{ hour.name }}
              </option>
            </select>
          </div>

          <span class="time-divider">:</span>

          <div class="form-group">
            <select class="form-control"
                    formControlName="minute">
              <option *ngFor="let minute of minutesArray | slice : 0 : (workHoursEnd.value.hour === 24 ? 1 : minutesArray.length)" [ngValue]="minute.value">
                {{ minute.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
    </div>


    <div class="form-group">
      <label>{{ 'INTERVIEW.SELECT_DURATION' | translate }}</label>
      <select class="form-control"
              (change)="setDuration($event.target.value)"
              [disabled]="time.value.length > 0">
        <option *ngFor="let duration of durations"
                [selected]="duration === selectedDuration"
                [ngValue]="duration">
          {{ duration }}
        </option>
      </select>
      <div class="invalid-feedback">
        {{ 'GENERIC_ERROR_MESSAGES.REQUIRED' | translate }}
      </div>
    </div>
    <div class="available-time-title-wrapper">
      <div class="available-time-title">
        {{ 'INTERVIEW.AVAILABLE_TIMES' | translate }}
      </div>
      <button class="confirm-btn" *ngIf="!openInModal && !isGroup" (click)="onSelectAll()">
        {{ 'USER.SELECT_ALL' | translate }}
      </button>
    </div>
    <div class="available-time-wrapper" [ngClass]="{'in-modal': openInModal}" *ngIf="availableTimes.length > 0; else noTimeSlots">
      <div *ngFor="let time of availableTimes; let i = index"
           class="available-time-item">
        <div class="time-btn" (click)="timeItemClicked(i)" [ngClass]="{'confirm-btn-visible': indexOfSlotReadyToConfirm === i}">
          {{ time | dateTime }}
        </div>
        <div class="confirm-btn" (click)="confirmButtonClicked(time)" *ngIf="indexOfSlotReadyToConfirm === i">
          {{ 'INTERVIEW.CONFIRM' | translate }}
        </div>
      </div>
    </div>
    <ng-template #noTimeSlots>
      {{ 'INTERVIEW.NO_AVAILABLE_TIMES' | translate }}
    </ng-template>
  </div>
</div>
